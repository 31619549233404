import React from 'react';
import Link from '@components/Link';
import { css } from '@oddcommon/utils';

import styles from './CTA.module.scss';

const CTA = props => {
  const { className, children, href, ...otherProps } = props;

  const anchorProps = { ...otherProps, href, className: css(className, styles.link) };

  const content = (
    <>
      <>{children}</>
      <span className={styles.arrow} />
    </>
  );

  return href ? (
    <a {...anchorProps} target="_blank" rel="noopener noreferrer">
      {content}
    </a>
  ) : (
    <Link {...anchorProps}>{content}</Link>
  );
};

export default CTA;
