import React, { useContext, useEffect } from 'react';
import { graphql } from 'gatsby';

import { GlobalContext } from '@context';

import styles from './index.module.scss';
import Meta from '@components/Meta';
import CTA from '@components/CTA';

const CareersPage = props => {
  const careers = props.data.allContentfulCareer.edges.map(edge => edge.node);
  const { actions } = useContext(GlobalContext);

  useEffect(() => {
    actions.setPageLoaded(true);
  }, []);

  return (
    <>
      <Meta title="OddCommon | Careers" />
      <div className={styles.base}>
        <section className={styles.openingSection}>
          <h1>
            People. Craft.
            <br />
            Client Experience.
          </h1>

          <p>
            Strip it all away. The fancy office, the unnecessary overhead, the people tasked with
            managing the unnecessary overhead.
          </p>
          <p>
            Timesheets, the word “synergy,” meetings, meetings about meetings, and that guy that
            does that thing that makes it hard for everyone to work.
          </p>
          <p>
            Leave only purity and focus. On creating exceptional experiences, on technical fluidity,
            on the betterment of artists and technologists, on the experience of working with us.
          </p>
        </section>
        <section>
          <h2>Openings</h2>
          <hr />
          {careers
            ? careers.map(({ slug, title, summary: { summary } }, i) => (
                <React.Fragment key={slug}>
                  <div className={styles.sideBySide}>
                    <h3>{title}</h3>
                    <div>
                      <p>{summary}</p>
                      <CTA to={`/careers/${slug}`} className={styles.apply}>
                        Learn More
                      </CTA>
                    </div>
                  </div>
                  {i < careers.length - 1 && <hr />}
                </React.Fragment>
              ))
            : ''}
        </section>

        <hr />

        <section className={styles.workingHereSection}>
          <h2>Working Here</h2>
          <hr />

          <div className={styles.sideBySide}>
            <h3>Benefits</h3>
            <div>
              <p>
                The stuff your parents are going to ask about. We’ve got it covered. Like, fully
                covered.
              </p>
              <p>
                Health, Dental and Vision benefits paid 100% by us. You contribute nothing, whether
                they’re for you or for your entire family.
              </p>
              <p>
                Free One Medical membership. Life and Disability insurance paid 100% by us. Pre-tax
                contribution options for health care, FSA, and dependent care. 401(k) with generous
                employer match.
              </p>
            </div>
          </div>

          <hr style={{ marginTop: '5rem', marginBottom: '0' }} />

          <div className={styles['triple-grid']}>
            <div>
              <h4>🌴 PTO</h4>
              <p>
                Take time off. All of it. Or else… (Just kidding, but not really. We make taking ALL
                of your PTO mandatory.)
              </p>
              <p>15 PTO days during years 1–2.</p>
              <p>20 PTO days at 2+ years.</p>
            </div>
            <div>
              <h4>🧠 Mental/Physical Health Day</h4>
              <p>You time.</p>
              <p>1 mental/physical health day per month.</p>
            </div>
            <div>
              <h4>😎 Sabbatical</h4>
              <p>Get out there and truly do something for yourself. Unplug. Reset. Chilllllll.</p>
              <p>3 week fully paid sabbatical during years 5, 7, and 9.</p>
            </div>
            <div>
              <h4>😷 Sick Days</h4>
              <p>Sick happens. These are for when the inevitable comes knocking.</p>
              <p>5 sick days per year.</p>
            </div>
            <div>
              <h4>📦 Moving Day</h4>
              <p>
                The two most stressful things in life are moving and public speaking. We pay you to
                do both of them.
              </p>
              <p>1 moving day per year if needed.</p>
            </div>
            <div>
              <h4>💪 Volunteer Days</h4>
              <p>It’s important. To you. To us. To the world. Let us help you help others.</p>
              <p>3 volunteer days per year + up to $1000 of donation match per year.</p>
            </div>
            <div>
              <h4>🏡 Flexible Work Environment</h4>
              <p>
                We’re firm believers in getting in a room and figuring it out. But we also recognize
                that WFH kind of rules. So, we’ll work with you to figure out the situation that
                works best.
              </p>
              <p>In-office and wfh options.</p>
            </div>
            <div>
              <h4>👶 Parental Leave</h4>
              <p>All (yes, all) caregivers are eligible.</p>
              <p>16 weeks. Fully paid.</p>
            </div>
          </div>

          <hr />

          <h3>Perks</h3>
          <div className={styles['triple-grid']}>
            <div>
              <h4>🚲 CitiBike Membership</h4>
              <p>Good for your legs. Good for the planet.</p>
              <p>CitiBike membership paid for 100% by us.</p>
            </div>
            <div>
              <h4>🍳 Breakfast</h4>
              <p>
                We love supporting local businesses. Especially when those businesses make breakfast
                tacos.
              </p>
              <p>Breakfast from a local spot to you once per month.</p>
            </div>
            <div>
              <h4>🎓 Student Loan Reimbursement</h4>
              <p>You did the time, now let us help you pay for it.</p>
              <p>Student loan reimbursement up to $1000/yr.</p>
            </div>
            <div>
              <h4>🐶 Pet Adoption Assistance</h4>
              <p>Dogs. Cats. Iguanas. Anything, really.</p>
              <p>We’ll give you up to $300/yr to bring home a new friend.</p>
            </div>
            <div>
              <h4>💉 Tattoo Reimbursement</h4>
              <p>This is not a joke. We know it seems like it. But it’s not.</p>
              <p>Up to $300/yr. Again, not a joke.</p>
            </div>
            <div>
              <h4>💐 Flowers</h4>
              <p>We send flowers to your house once per month. Cool design-y ones.</p>
              <p>(Also not a joke.)</p>
            </div>
          </div>

          <hr />

          <div className={styles.sideBySide}>
            <h3>Our Commitment to the Environment</h3>
            <div>
              <p>
                We need our planet. Obviously. We see it as one of our main responsibilities as a
                company to take care of it as best we can.
              </p>
              <p>
                Our goal is to be carbon neutral (even carbon negative). So we channel a portion of
                our profits into offsetting our carbon footprint each year. But we take it even one
                step further by offsetting the personal carbon footprints of each of our employees.
              </p>
              <p>
                The average American citizen puts 17,000 lbs of CO2 into the atmosphere each year.
                We’ll offset that.
              </p>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export const CareersListQuery = graphql`
  query CareersListQuery {
    allContentfulCareer(filter: { public: { eq: true } }) {
      edges {
        node {
          title
          slug
          summary {
            summary
          }
        }
      }
    }
  }
`;
export default CareersPage;
